export function loginWatcher(authParams, resolve, reject) {
  return {
    type: "LOGIN_WATCHER",
    payload: authParams,
    resolve,
    reject,
  };
}

export function createUserWatcher(authParams, resolve, reject) {
  return {
    type: "CREATE_USER_WATCHER",
    payload: authParams,
    resolve,
    reject,
  };
}

export function userListWatcher(data, resolve, reject) {
  return {
    type: "USER_LIST_WATCHER",
    payload: data,
    resolve,
    reject,
  };
}

export function toggleUserStatusWatcher(data, resolve, reject) {
  return {
    type: "TOGGLE_USER_STATUS",
    payload: data,
    resolve,
    reject,
  };
}

export const updatePreLoaderWatcher = (payload) => ({
  type: "UPDATE_PRE_LOADER_WATCHER",
  payload,
});

export function updateProfile(payload) {
  return { type: "UPDATE_PROFILE", payload };
}

export function getUsersAllWatcher(data, resolve, reject) {
  return {
    type: "USER_LIST_WATCHER",
    payload: data,
    resolve,
    reject,
  };
}

export function getUserProfileWatcher(data, resolve, reject) {
  return {
    type: "GET_ALL_USERS_PROFILE_WATCHER",
    payload: data,
    resolve,
    reject,
  };
}

export function getKycDetailsWatcher(data, resolve, reject) {
  return {
    type: "GET_KYC_DETAILS_WATCHER",
    payload: data,
    resolve,
    reject,
  };
}

export function resetpasswordWatcher(data, resolve, reject) {
  return {
    type: "GET_OTP_FOR_RESET_PASSWORD",
    payload: data,
    resolve,
    reject,
  };
}

export function validateOtpWatcher(data, resolve, reject) {
  return {
    type: "VALIDATE_OTP_FOR_RESET_PASSWORD",
    payload: data,
    resolve,
    reject,
  };
}


