import { takeLatest, call, put } from "redux-saga/effects";
import { onboardIssuer } from "../apis/issuer";

export function* onboardIssuerEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const response = yield call(onboardIssuer, action.payload);
    action.resolve(response.json());
  } catch (e) {
    action.reject(e);
  }
}

export function* onboardIssuerWatcherSaga() {
  yield takeLatest("ONBOARD_ISSUER", onboardIssuerEffectSaga);
}
