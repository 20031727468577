import axios from "axios";

import { ALDIRA_URL,BASE_URL } from "../constants/apiUrls";
import { getAPIHeaders } from "../util/setAuthToken";

export async function fetchKYCStatus() {
  const headers = getAPIHeaders();
  const result = await axios.get(ALDIRA_URL + "analysis/get-kyc-status", { headers });
    return result ;
  }

  export async function fetchRegisterUsers() {
  const headers = getAPIHeaders();
  const result = await axios.get(ALDIRA_URL + "analysis/get-verified-user-count", { headers });
    return result ;
  }

  export async function uploadReportApi(payload,file) {
    const result = await axios.post(`${BASE_URL}store_report/${payload.report_name}/${payload.report_date}`,file);
    return result ;
  }

  export async function fetchReportApi(payload) {
    const result = await axios.post(`${BASE_URL}reports`,payload);
    return result ;
  }
  
  export async function downloadReportApi(payload) {
    const result = await axios.get(`${BASE_URL}download_report/${payload.id}`);
    return result ;
  }