/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//React
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";


// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import CMTableHeader from "../../../components/CMTable/CMTableHeader";
import { userTable } from "./UserTableProps";


//Calling API for register users count and KYC status
import { getRegisterUserWatcher,getKYCStatusWatcher } from "../../../actions/analysis";
import {  getKycDetailsWatcher } from "../../../actions/user";
import { setAlert } from "../../../actions/common";



function Analytics() {
  const { sales, tasks } = reportsLineChartData;

  const dispatch = useDispatch();
  const [totalRegisterUser, setTotalRegisterUser] = useState("");
  const [kycStatusDetails, setKycStatusDetails] = useState("");
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kycStatus, setKycStatus] = useState("");
  const [KycDetailList, setKycDetailList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const analysisHandler = () => {
    new Promise((resolve, reject) => {
      dispatch(getRegisterUserWatcher( resolve, reject));
    })
      .then((response) => {
        setTotalRegisterUser(response.total);
      })
      new Promise((resolve, reject) => {
        dispatch(getKYCStatusWatcher( resolve, reject));
      })
        .then((response) => {
          setKycStatusDetails(response.kyc_status_details);
        })
  };

  useEffect(() => {
    analysisHandler();
  }, []);

  //KYC List 

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const fetchUserList = () => {
    const payload = {
      email,
      phoneNumber,
      kycStatus,
      page,
      limit: rowsPerPage,
    };
    new Promise((resolve, reject) => {
      dispatch(getKycDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setKycDetailList(response.data);
        setCount(response.totalCount);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    fetchUserList();
  }, [page, rowsPerPage]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setKycDetailList("");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    // setKycDetailList("");
  };

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          {/* <SalesByCountry /> */}
        </Grid>
        
        <MDBox mt={1.5} >
        {kycStatusDetails && (
          <Grid container spacing={3}>
            {/*  */}
          {
          kycStatusDetails.map((item, index) => (
            <Grid item xs={12} md={6} lg={3}>
                <ComplexStatisticsCard key={index}
                  icon="leaderboard"
                  title={"KYC " + item.kyc_status}
                  count={item.count}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
            </Grid>
            ))}
            <Grid item xs={12} md={6} lg={3}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Total Register Users"
                  count={totalRegisterUser}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
            </Grid>
          </Grid>
          )}
        </MDBox>
        {KycDetailList ? (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          <Grid xs={12} item>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <CMTableHeader headers={userTable} />
              <TableBody>
                {KycDetailList &&
                  KycDetailList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">{item.kyc_status}</TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">{item.phone_number}</TableCell>
                      <TableCell align="left">{item.is_email_verified.toString()}</TableCell>
                      <TableCell align="left">{item.is_phone_number_verified.toString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
