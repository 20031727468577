import { all } from "redux-saga/effects";

import {
  getRoleMetrixWatcherSaga,
  addDesignationWatcherSaga,
  addDepartmentWatcherSaga,
  addRoleWatcherSaga,
  getRoleWatcherSaga,
  getDepartmentWatcherSaga,
  getDesignationWatcherSaga,
} from "./roleMetrix";

import { addProductWatcherSaga } from "./product";
import { onboardIssuerWatcherSaga } from "./issuer";
import {
  loginWatcherSaga,
  createUserWatcherSaga,
  userListWatcherSaga,
  toggleUserStatusWatcherSaga,
  getUserProfileWatcherSaga,
  getKycDetailsWatcherSaga,
  resetPasswordWatcherSaga,
  validateOtpResetPassWatcherSaga
} from "./user";
import {
  fetchKYCStatusDetailsWatcherSaga,
  fetchRegisterUserWatcherSaga,
  uploadReportWatcherSaga,
  fetchReportWatcherSaga,
  downloadReportWatcherSaga
} from "./analysis";

export default function* rootSaga() {
  yield all([
    getRoleMetrixWatcherSaga(),
    addDesignationWatcherSaga(),
    addDepartmentWatcherSaga(),
    addRoleWatcherSaga(),
    getRoleWatcherSaga(),
    getDepartmentWatcherSaga(),
    getDesignationWatcherSaga(),
    addProductWatcherSaga(),
    onboardIssuerWatcherSaga(),
    loginWatcherSaga(),
    createUserWatcherSaga(),
    userListWatcherSaga(),
    toggleUserStatusWatcherSaga(),
    fetchKYCStatusDetailsWatcherSaga(),
    fetchRegisterUserWatcherSaga(),
    getUserProfileWatcherSaga(),
    getKycDetailsWatcherSaga(),
    resetPasswordWatcherSaga(),
    validateOtpResetPassWatcherSaga(),
    uploadReportWatcherSaga(),
    fetchReportWatcherSaga(),
    downloadReportWatcherSaga()
  ]);
}
