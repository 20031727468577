import axios from "axios";
import { BASE_URL } from "../constants/apiUrls";

export async function onboardIssuer(payload) {
  await axios
    .post(BASE_URL + "issuer", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
