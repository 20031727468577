import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useDispatch } from "react-redux";
import { setAlert } from "../actions/common";
import { loginWatcher } from "../actions/user";

function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClear = () => {
    setPassword("");
    setEmail("");
  };

  const handleLogin = () => {
    const postData = {
      username: email,
      password,
    };
    const p = new Promise((resolve, reject) => {
      dispatch(loginWatcher(postData, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, "Login Successfully", "success"));
          navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(setAlert(false, error.response.data.message, "error"));
      });
  };

  const clearCacheFun = () => {
    localStorage.clear();
    navigate("/authentication/log-in");
  };

  const handleResetPage = () => {
    navigate("/authentication/reset-password");
  }

  useEffect(() => {
    clearCacheFun();
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>

          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                type="text"
                label="Email"
                placeholder="Email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                type="password"
                label="Password"
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <Button
                variant="contained"
                className="pull-right ml-4 mr-3"
                fullWidth
                onClick={handleLogin}
                sx={{
                  color: "#fff",
                }}
              >
                sign in
              </Button>
            </MDBox>
          </MDBox>
              <MDTypography variant="button" fontWeight="small" onClick={handleResetPage}>
                &nbsp;Reset password&nbsp;
              </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
