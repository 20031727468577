export const userTable = {
  head: [
    {
      title: "Kyc status",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Email",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Phone number",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Is email verified",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Is phone number verified",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};
