export const roleTable = {
  head: [
    {
      title: "Role",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};

export const departmentTable = {
  head: [
    {
      title: "Department",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};

export const designationTable = {
  head: [
    {
      title: "Designation",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};
