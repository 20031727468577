import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { storedList } from "../../util/localstorage";
import MDDatePicker from "../../components/MDDatePicker/index.js";
import CMTableHeader from "../../components/CMTable/CMTableHeader";
import { setAlert } from "../../actions/common";
import CustomDropdown from "../../components/custom/customSelect";
import {  getKycDetailsWatcher } from "../../actions/user";
import { userTable } from "./UserTableProps";


export default function CreateUser() {
  const [searching, setSearching] = useState(false);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kycStatus, setKycStatus] = useState("");
  const [KycDetailList, setKycDetailList] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchKycStatus, setSearchKycStatus] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [searchCreatedDate, setSearchCreatedDate] = useState("");
  const [submittedDate, setSubmittedDate] = useState("");
  const [searchSubmittedDate, setSearchSubmittedDate] = useState("");
  const [completedDate, setCompletedDate] = useState("");
  const [searchCompletedDate, setSearchCompletedDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(storedList("user"));
    window.addEventListener("storage", () => {
      setUser(storedList("user"));
    });
  }, []);

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const searchKycList = () => {
    setPage(0);    
    setSearchEmail(email);
    setSearchPhoneNumber(phoneNumber);
    setSearchKycStatus(kycStatus);
    setSearchCreatedDate(createdDate);
    setSearchSubmittedDate(submittedDate);
    setSearchCompletedDate(completedDate);
  };

  const fetchUserList = (payload) => {
    new Promise((resolve, reject) => {
      dispatch(getKycDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setKycDetailList(response.data);
        setCount(response.totalCount);
      })
      .catch((error) => {
        setKycDetailList([]);
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    const payload = {
      email : searchEmail,
      phoneNumber: searchPhoneNumber,
      kycStatus: searchKycStatus.value,
      created_at: searchCreatedDate,
      registration_date: searchSubmittedDate,
      approval_date: searchCompletedDate,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  }, [page, rowsPerPage]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  useEffect(() => {
    const payload = {
      email : searchEmail,
      phoneNumber: searchPhoneNumber,
      kycStatus: searchKycStatus.value,
      created_at: searchCreatedDate,
      registration_date: searchSubmittedDate,
      approval_date: searchCompletedDate,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  }, [searchEmail, searchKycStatus,searchPhoneNumber,searchCreatedDate,searchSubmittedDate,searchCompletedDate]);

  const handleClear = () => {
    setSearchPhoneNumber("");
    setSearchKycStatus("");
    setSearchEmail("");
    setSearchCreatedDate("");
    setSearchSubmittedDate("");
    setSearchCompletedDate("");
    const payload = {
      email : searchEmail,
      phoneNumber: searchPhoneNumber,
      kycStatus: searchKycStatus.value,
      created_at: searchCreatedDate,
      registration_date: searchSubmittedDate,
      approval_date: searchCompletedDate,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  };

  const kycStatusValues = [
    {
      value: 'complete',
      label: 'complete'
    },
    {
      value: 'not-submitted',
      label: 'not-submitted'
    },
    {
      value: 'submitted',
      label: 'submitted'
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        {user.type === "admin" && (
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Email"
                  value={email}
                  type="text"
                  onChange={(e) => {setEmail(e.target.value)}}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Phone number"
                  value={phoneNumber}
                  type="text"
                  onChange={handleInputChange( setPhoneNumber)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%"}}>
              <CustomDropdown placeholder='KYC status'
                data={kycStatusValues}
                value={kycStatus}
                handleDropdownChange={(value) => setKycStatus(value)}
                />
                </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
            <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
              <MDDatePicker
              input={{ placeholder: "Not Submitted Date" }} 
                value={createdDate}
                options={{
                  enableTime: false,
                  dateFormat: "d-m-y",
              }}
                onChange={(date,dateStr) => {
                  setCreatedDate(dateStr);
                }}
              />
            </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
            <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
              <MDDatePicker
              input={{ placeholder: "Submitted Date" }} 
                value={submittedDate}
                options={{
                  enableTime: false,
                  dateFormat: "d-m-y",
              }}
                onChange={(date,dateStr) => {
                  setSubmittedDate(dateStr);
                }}
              />
            </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
            <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
              <MDDatePicker
              input={{ placeholder: "Completed Date" }} 
                value={completedDate}
                options={{
                  enableTime: false,
                  dateFormat: "d-m-y",
              }}
                onChange={(date,dateStr) => {
                  setCompletedDate(dateStr);
                }}
              />
            </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} marginY={1} item>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={handleClear}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={searchKycList}
                  sx={{
                    color: "#fff",
                  }}
                >
                  {searching ? "Searching" : "Search"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
          {KycDetailList && KycDetailList.length ? (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          <Grid xs={12} item>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <CMTableHeader headers={userTable} />
              <TableBody>
                {KycDetailList &&
                  KycDetailList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">{item.kyc_status}</TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">{item.phone_number}</TableCell>
                      <TableCell align="left">{item.is_email_verified.toString()}</TableCell>
                      <TableCell align="left">{item.is_phone_number_verified.toString()}</TableCell>
                      <TableCell align="left">{item.created_at}</TableCell>
                      <TableCell align="left">{item.approval_date}</TableCell>
                      <TableCell align="left">{item.registrasi_date}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
