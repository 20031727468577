import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomSelect from "../../components/custom/customSelect";
import MDDatePicker from "../../components/MDDatePicker/index.js";
import { setAlert } from "../../actions/common";
import { uploadReportWatcher } from "../../actions/analysis";

export default function AddReports(props) {
  const defaultErrors = {
    nameError: false,
    loanTemplatesError: false,
    templateNameError: false,
  };

  const dispatch = useDispatch();
  const [reportName, setReportName] = useState("");
  const [inputFileName, setInputFileName] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const onFileChangeHandler = (event) => {
    setInputFileName(event.target.files[0]);
  };

  const handleClear = () => {
    setReportName("");
    setReportDate("");
  };

  const handleSubmit = () => {
    const file = new FormData();
    file.append("file", inputFileName);

    if (reportName === "") {
      dispatch(setAlert(false, "Report name is required", "error"));
      return;
    }
    if (reportDate === "") {
      dispatch(setAlert(false, "Date is required", "error"));
      return;
    }
    const reqData = {
      report_name: reportName,
      report_date : formatedDate,
    };
    new Promise((resolve, reject) => {
      dispatch(uploadReportWatcher(reqData, file, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, response.message, "success"));
        handleClear();
      })
      .catch((error) => {
        dispatch(setAlert(false, error.response.data.message, "error"));
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid xs={12} container>
          <Grid
            xs={3}
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <FormControl
              variant="filled"
              component={Box}
              marginBottom="1.5rem!important"
            >
              <TextField
                id="outlined-basic"
                label="Report name"
                variant="outlined"
                type="text"
                placeholder="Report name"
                value={reportName}
                size="medium"
                onChange={handleInputChange(
                  setReportName
                )}
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              marginBottom="1.5rem!important"
            >
              <MDDatePicker
              input={{ placeholder: "Report date" }} 
                value={reportDate}
                options={{
                  enableTime: false,
                  dateFormat: "Y-m-d",
              }}
                onChange={(date,dateStr) => {
                  setFormatedDate(dateStr);
                  setReportDate(date);
                }}
              />
            </FormControl>
            
            <FormControl
              variant="filled"
              component={Box}
              marginBottom="1.5rem!important"
            >
              <Grid item md="12" className="fileinput">
                <Typography
                  component="span"
                  variant="body2"
                  color="black"
                  opacity={0.8}
                  sx={{ lineHeight: 0 }}
                >
                  Select the template in .xlsx format only
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  color="white"
                  opacity={0.8}
                  sx={{ lineHeight: 2 }}
                >
                  <input
                    type="file"
                    name="file"
                    accept=".xlsx"
                    onChange={(e) => onFileChangeHandler(e, inputFileName)}
                  />
                </Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <Button onClick={handleSubmit} variant="contained">
          SUBMIT
        </Button>
      </MDBox>
    </DashboardLayout>
  );
}
