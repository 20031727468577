import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function CustomSelect({
  classes,
  isRefresh,
  placeholder,
  data,
  valueData,
  multiple,
  isDisabled,
  handleDropdownChange,
  id,
  error,
  helperText,
}) {
  const handleChange = (e, value) => {
    handleDropdownChange(value);
  };

  return (
    <Autocomplete
      multiple={multiple}
      value={valueData}
      id={id}
      options={data}
      noOptionsText="No record"
      onChange={handleChange}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField {...params} error={error} helperText={helperText || ""} label={placeholder} />
      )}
    />
  );
}
