import { handleActions } from "redux-actions";

export default handleActions(
  {
    SET_ALERT_POPUP: (state, { payload }) => {
      return {
        ...state,
        alert: payload,
      };
    },
  },
  {
    alert: {
      hidden: true,
      message: "",
      type: "",
      title: "",
    },
  }
);
