export const validateData = (type, value) => {
  switch (type) {
    case "string": {
      const string = /^.{1,250}$/;
      return string.test(value);
    }
    case "phone": {
      const phone = /^(\d{11})$/;
      return phone.test(value);
    }
    case "email": {
      const email = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;
      return email.test(value);
    }
    case "date": {
      const date = /^\d{4}-\d{2}-\d{2}$/;
      return date.test(value);
    }
    case "float": {
      const float = /^[+-]?\d+(\.\d+)?$/;
      return float.test(value);
    }
    case "number": {
      const number = /^[0-9]*$/;
      return number.test(value);
    }
    case "boolean": {
      const boolean = /^(true|false)$/;
      return boolean.test(value);
    }
    default: {
      return true;
    }
  }
};
