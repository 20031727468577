import { takeLatest, call, put, all } from "redux-saga/effects";
import { loginApi, createUserApi, userListApi, toggleUserStatusApi, getUserAllProfileApi, getKycDetailsApi, 
  resetPasswordApi,validateOtpResetPassApi } from "../apis/user";
import { updateProfile, updatePreLoaderWatcher } from "../actions/user";
import setAuthToken from "../util/setAuthToken";
import { saveToStorage } from "../util/localstorage";

/** saga worker that is responsible for the side effects */
export function* loginEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const response = yield call(loginApi, action.payload);
    const { data } = response;
    // store data to localStorage
    Object.keys(data).forEach((key) => {
      saveToStorage("auth", true);
      saveToStorage(key, data[key]);
    });
    const { user } = data;
    setAuthToken(JSON.parse(localStorage.getItem("token")));
    //update the reducer
    yield put(updateProfile(user));
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* loginWatcherSaga() {
  yield takeLatest("LOGIN_WATCHER", loginEffectSaga);
}

export function* createUserEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(createUserApi, action.payload); // store data to localStorage
    Object.keys(data).forEach((key) => {
      saveToStorage(key, data[key]);
    });
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* createUserWatcherSaga() {
  yield takeLatest("CREATE_USER_WATCHER", createUserEffectSaga);
}

export function* userListEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(userListApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* userListWatcherSaga() {
  yield takeLatest("USER_LIST_WATCHER", userListEffectSaga);
}

export function* toggleUserStatusEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(toggleUserStatusApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* toggleUserStatusWatcherSaga() {
  yield takeLatest("TOGGLE_USER_STATUS", toggleUserStatusEffectSaga);
}

export function* getUserProfileEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getUserAllProfileApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getUserProfileWatcherSaga() {
  yield takeLatest("GET_ALL_USERS_PROFILE_WATCHER", getUserProfileEffectSaga);
}

export function* getKycDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getKycDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getKycDetailsWatcherSaga() {
  yield takeLatest("GET_KYC_DETAILS_WATCHER", getKycDetailsEffectSaga);
}

export function* resetPasswordEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(resetPasswordApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* resetPasswordWatcherSaga() {
  yield takeLatest("GET_OTP_FOR_RESET_PASSWORD", resetPasswordEffectSaga);
}

export function* validateOtpResetPassEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(validateOtpResetPassApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* validateOtpResetPassWatcherSaga() {
  yield takeLatest("VALIDATE_OTP_FOR_RESET_PASSWORD", validateOtpResetPassEffectSaga);
}

