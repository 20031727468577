import { setAlert } from "actions/common";
import MDSnackbar from "components/MDSnackbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Alert() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.common.alert);
  const [alertData, setAlertData] = useState({});

  useEffect(() => {
    const obj = {
      open: !alert.hidden,
      content: alert.message,
      color: alert.type || "light",
      title: alert?.tiltle || "Notification",
      icon: "notifications",
    };
    if (alert.type === "success") {
      obj.title = "Successful";
      obj.icon = "check";
    }
    if (alert.type === "error") {
      obj.title = "Error";
      obj.icon = "warning";
    }
    if (alert.type === "warning") {
      obj.title = "Warning";
      obj.icon = "star";
    }
    setAlertData(obj);
  }, [alert]);

  const closeSB = () => {
    dispatch(setAlert(true, "", ""));
  };

  return <MDSnackbar {...alertData} onClose={closeSB} close={closeSB} bgWhite />;
}
