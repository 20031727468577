import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CreateRole from "./role";
import CreateDepartment from "./department";
import CreateDesignation from "./designation";

function RoleMetrix() {
  const [value, setValue] = useState("Role");
  const [showRole, setShowRole] = useState(true);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showDesignation, setShowDesignation] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === "Role") {
      setValue("Role");
      setShowRole(true);
      setShowDepartment(false);
      setShowDesignation(false);
    } else if (newValue === "Department") {
      setValue("Department");
      setShowDepartment(true);
      setShowRole(false);
      setShowDesignation(false);
    } else {
      setValue("Designation");
      setShowDesignation(true);
      setShowDepartment(false);
      setShowRole(false);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            textColor: "#11ceef",
            indicatorColor: "#11ceef",
          }}
          aria-label="secondary tabs example"
        >
          <Tab value="Role" label="Role" />
          <Tab value="Department" label="Department" />
          <Tab value="Designation" label="Designation" />
        </Tabs>
        <Grid item xs={12}>
          {showRole ? (
            <CreateRole />
          ) : showDepartment ? (
            <CreateDepartment />
          ) : showDesignation ? (
            <CreateDesignation />
          ) : null}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default RoleMetrix;
