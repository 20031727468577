import axios from "axios";

import { BASE_URL, ALDIRA_URL } from "../constants/apiUrls";
import { getAPIHeaders } from "../util/setAuthToken";

export function loginApi(payload) {
  return axios.post(BASE_URL + "login", payload);
}

export function createUserApi(payload) {
  return axios.post(BASE_URL + "user", payload);
}

export function userListApi(payload) {
  return axios.get(BASE_URL + "user", payload);
}

export function toggleUserStatusApi(payload) {
  return axios.put(BASE_URL + "updateUserStatus", payload);
}

export function getUserAllProfileApi(payload) {
  const headers = getAPIHeaders();
  return axios.post(ALDIRA_URL + "user/all-profile", payload, { headers });
}

export function getKycDetailsApi(payload) {
  const headers = getAPIHeaders();
  return axios.post(ALDIRA_URL + "user/get-kyc-details", payload, { headers });
}

export function resetPasswordApi(payload) {
  return axios.post(BASE_URL + "user/reset-password", payload);
}

export function validateOtpResetPassApi(payload) {
  return axios.post(BASE_URL + "user/reset-pass-val-otp", payload);
}
