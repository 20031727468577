import { takeLatest, call } from "redux-saga/effects";
import {
    fetchKYCStatus,
    fetchRegisterUsers,
    uploadReportApi,
    fetchReportApi,
    downloadReportApi
  } from "../apis/analysis";



export function* fetchKYCStatusDetailsEffectSaga(action) {
    // data is obtained after axios call is resolved
    try {
      const { data } = yield call(fetchKYCStatus);
      action.resolve(data);
    } catch (e) {
      action.reject(e);
    }
  }

  export function* fetchKYCStatusDetailsWatcherSaga() {
    yield takeLatest("GET_KYC_STATUS", fetchKYCStatusDetailsEffectSaga);
  }

  export function* fetchRegisterUserEffectSaga(action) {
    // data is obtained after axios call is resolved
    try {
      const { data } = yield call(fetchRegisterUsers);
      action.resolve(data);
    } catch (e) {
      action.reject(e);
    }
  }

  export function* fetchRegisterUserWatcherSaga() {
    yield takeLatest("GET_REGISTER_USERS", fetchRegisterUserEffectSaga);
  }

export function* uploadReportEffectSaga(action) {
  try {
    const { data } = yield call(uploadReportApi, action.payload, action.file);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* uploadReportWatcherSaga() {
  yield takeLatest("UPLOAD_REPORT_WATCHER", uploadReportEffectSaga);
}

export function* fetchReportEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const { data } = yield call(fetchReportApi, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* fetchReportWatcherSaga() {
  yield takeLatest("GET_REPORT_WATCHER", fetchReportEffectSaga);
}

export function* downloadReportEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const { data } = yield call(downloadReportApi, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* downloadReportWatcherSaga() {
  yield takeLatest("DOWNLOAD_REPORT_WATCHER", downloadReportEffectSaga);
}

