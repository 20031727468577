export function getKYCStatusWatcher(resolve, reject) {
    return {
        type: "GET_KYC_STATUS",
        resolve,
        reject,
    };
  }

  export function getRegisterUserWatcher(resolve, reject) {
    return {
        type: "GET_REGISTER_USERS",
        resolve,
        reject,
    };
  }

  export function uploadReportWatcher(data, file, resolve, reject) {
    return {
      type: "UPLOAD_REPORT_WATCHER",
      payload: data,
      file,
      resolve,
      reject,
    };
  }
  
  export function getReportWatcher(data,resolve, reject) {
    return {
        type: "GET_REPORT_WATCHER",
        payload: data,
        resolve,
        reject,
    };
  }

  export function downloadReportWatcher(data,resolve, reject) {
    return {
        type: "DOWNLOAD_REPORT_WATCHER",
        payload: data,
        resolve,
        reject,
    };
  }

  
  
  