import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function CMTableHeader(props) {
  const { headers, cells } = props;
  return (
    <TableHead sx={{ width: "100%", display: "contents" }}>
      <TableRow sx={{ width: "100%" }}>
        {headers?.head.map((item) => {
          return (
            <TableCell component="th" sx={item.css} align="left" key={item.title}>
              {item.title}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
