import axios from "axios";

const setAuthToken = (token, selectedPartnerId) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const getAPIHeaders = () => {
  // Returns api headers with x-api-key added & Authorization removed
  const headers = {
    'x-api-key': process.env.REACT_APP_AUTH_KEY,
  };
  delete axios.defaults.headers.common["Authorization"];
  return headers;
};

export default setAuthToken;
