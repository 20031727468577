import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import KeyIcon from "@mui/icons-material/Key";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { storedList } from "util/localstorage";
import { validateData } from "util/validation";
import { setAlert } from "actions/common";
import CMTableHeader from "../../components/CMTable/CMTableHeader";

import { createUserWatcher, getUsersAllWatcher, toggleUserStatusWatcher } from "../../actions/user";
import CustomDropdown from "../../components/custom/customSelect";
import DesignationsDropDown from "../../components/Dropdowns/DesignationDropDown";
import DepartmentDropDown from "../../components/Dropdowns/DepartmentDropDown";
import RolesDropDown from "../../components/Dropdowns/RolesDropDown";
import { userTable } from "./UserTableProps";

import { userTypeOptions } from "./UserTableProps";

const defaultErrors = {
  nameError: false,
  emailError: false,
  typeError: false,
  designationError: false,
  departmentError: false,
  userRolesError: false,
};

export default function CreateUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [userRoles, setUserRoles] = useState(null);
  const [errors, setErrors] = useState(defaultErrors);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({});
  const [userList, setUserList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(storedList("user"));
    window.addEventListener("storage", () => {
      setUser(storedList("user"));
    });
  }, []);

  const fetchUserList = () => {
    const payload = {};
    new Promise((resolve, reject) => {
      dispatch(getUsersAllWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setUserList(response);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleInputChange = (field, validationType, setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
    setErrors({
      ...errors,
      [field + "Error"]: !validateData(validationType, value),
    });
  };

  const handleDropdownChange = (field, value, setValue, isMulti) => {
    setValue(value);
    setErrors({
      ...errors,
      [field + "Error"]: isMulti ? !value?.length : !value,
    });
  };

  const handleClear = () => {
    setName("");
    setEmail("");
    setType("");
    setDesignation("");
    setDepartment("");
    setUserRoles([]);
    setErrors(defaultErrors);
  };

  const validate = () => {
    if (!validateData("string", name)) {
      setErrors({ ...defaultErrors, nameError: true });
      return false;
    }
    if (!validateData("email", email)) {
      setErrors({ ...defaultErrors, emailError: true });
      return false;
    }
    if (!type?.value) {
      setErrors({ ...defaultErrors, typeError: true });
      return false;
    }
    if (!designation?.value) {
      setErrors({ ...defaultErrors, designationError: true });
      return false;
    }
    if (!department?.length) {
      setErrors({ ...defaultErrors, userRolesError: true });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (saving) return;
    if (!validate()) return;
    setSaving(true);
    const payload = {
      username: name,
      email,
      type: type.value,
      designation: designation.value,
      department: department.map((item) => item.value),
      userroles: userRoles.map((role) => role.value),
    };
    new Promise((resolve, reject) => {
      dispatch(createUserWatcher(payload, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, response.message, "success"));
        setSaving(false);
        handleClear();
        fetchUserList();
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
        setSaving(false);
      });
  };

  const toggleUserStatus = (e, id) => {
    const data = {
      id,
      status: e.target.checked ? true : false,
    };
    new Promise((resolve, reject) => {
      dispatch(toggleUserStatusWatcher(data, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, response.message, "success"));
        fetchUserList();
      })
      .catch((error) => {
        dispatch(setAlert(false, error.response.data.message, "error"));
      });
  };

  const handleUpdateUser = (row) => {
    const url = `/access/users/edituser/${row._id}`;
    navigate(url);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        {user.type === "admin" && (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="User Name"
                  value={name}
                  type="text"
                  error={errors.nameError}
                  helperText={errors.nameError ? "Enter valid name" : ""}
                  onChange={handleInputChange("name", "string", setName)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Email"
                  value={email}
                  type="text"
                  error={errors.emailError}
                  helperText={errors.emailError ? "Enter valid email address" : ""}
                  onChange={handleInputChange("email", "email", setEmail)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <CustomDropdown
                placeholder="Select type of user"
                data={userTypeOptions}
                value={type}
                handleDropdownChange={(value) => {
                  if (value?.value === "admin") handleDropdownChange("type", value, setType, false);
                }}
                error={errors.typeError}
                helperText={errors.typeError ? "User type is required" : ""}
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl
                variant="filled"
                component={Box}
                width="100%"
                marginBottom="1.5rem!important"
              >
                <DesignationsDropDown
                  id="select-designation"
                  placeholder="Select designation"
                  valueData={designation}
                  error={errors.designationError}
                  helperText={errors.designationError ? "Designation is required" : ""}
                  onValueChange={(value) =>
                    handleDropdownChange("designation", value, setDesignation, false)
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <DepartmentDropDown
                id="select-department"
                placeholder="Select department"
                valueData={department}
                error={errors.departmentError}
                helperText={errors.departmentError ? "Department is required" : ""}
                onValueChange={(value) =>
                  handleDropdownChange("department", value, setDepartment, false)
                }
              />
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <RolesDropDown
                id="select-role"
                placeholder="Select role"
                valueData={userRoles}
                error={errors.userRolesError}
                helperText={errors.userRolesError ? "Atleast 1 role is required" : ""}
                onValueChange={(value) =>
                  handleDropdownChange("userRoles", value, setUserRoles, true)
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={12} marginY={1} item>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={handleClear}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={handleSubmit}
                  sx={{
                    color: "#fff",
                  }}
                >
                  {saving ? "Saving" : "Save"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
        {userList && (
          <Grid xs={12} item>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <CMTableHeader headers={userTable} />
              <TableBody>
                {userList &&
                  userList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">{index + 1}</TableCell>
                      <TableCell scope="row">{item.username}</TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">{item.type}</TableCell>
                      <TableCell align="left">
                        <Switch
                          color="primary"
                          checked={item.status ? true : false}
                          onChange={(e) => toggleUserStatus(e, item._id)}
                        />
                      </TableCell>
                      {/* <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            handleUpdateUser(item);
                          }}
                          aria-label="Update user"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
