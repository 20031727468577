export const userTable = {
  head: [
    {
      title: "Report Name",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Date",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Action",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};

export const userTypeOptions = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "company",
    label: "Company",
  },
];
