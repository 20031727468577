import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getRoleWatcher } from "../../actions/roleMetrix";
import CustomDropdown from "../custom/customSelect";

function RolesDropDown(props) {
  const { onValueChange, placeholder, valueData, id, helperText, ...other } = props;
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRoleWatcher(
        (result) => {
          setRoles(
            result.map((item) => {
              return {
                value: item.title,
                label: item.title,
              };
            })
          );
        },
        (error) => {}
      )
    );
  }, []);

  return (
    <CustomDropdown
      placeholder={placeholder}
      data={roles}
      value={valueData}
      id={id}
      multiple="true"
      handleDropdownChange={onValueChange}
      helperText={helperText}
    />
  );
}

RolesDropDown.propTypes = {
  children: PropTypes.node,
};

RolesDropDown.defaultProps = {
  children: "",
};

export default RolesDropDown;
