import React from "react";
// @material-ui/core components
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import showcase from '../../assets/images/showcase.jpg';


function Dashboard() {

  return (
    <DashboardLayout>

    <div className='content'>
    <div style={{position:"relative"}}>
            <div style={{background: "#fff", position:"absolute"}}>
            <Box
        component="img"
        style={{maxWidth: "100%",height:"auto"}}
        alt="Welcome to Global Dashboard"
        src={showcase}
      />
                <Typography variant="h5" style={{position:"absolute",top:"7%",m:"16px"}}>
                    Welcome to
                </Typography>
                <Typography variant="h2" style={{position:"absolute",top:"10%",m:"14px"}}>
                    Global Dashboard
                </Typography>
            </div>

        </div>
        </div>
    </DashboardLayout>
  );
}

export default Dashboard;
