import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { setAlert } from "../../actions/common";
import { addRoleWatcher, getRoleWatcher } from "../../actions/roleMetrix";
import CMTableHeader from "../../components/CMTable/CMTableHeader";
import { roleTable } from "./roleMetrixTable";

function CreateRole() {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);

  const getRoleList = () => {
    new Promise((resolve, reject) => {
      dispatch(getRoleWatcher(resolve, reject));
    })
      .then((response) => {
        setRoleList(response);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const handleSubmit = () => {
    const data = {
      title: role,
    };
    if (role === "") {
      dispatch(setAlert(false, "title is required", "error"));
      return;
    }
    new Promise((resolve, reject) => {
      dispatch(addRoleWatcher(data, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, response.message, "success"));
        setRole("");
        getRoleList();
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  return (
    <MDBox py={3} mb={20} height="65vh">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl
            variant="filled"
            component={Box}
            width="100%"
            marginBottom="1.5rem!important"
          >
            <TextField
              id="outlined-basic"
              label="Role"
              variant="outlined"
              type="text"
              placeholder="Role"
              value={role}
              onChange={(event) => {
                setRole(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Box textAlign="center" marginTop="0.5rem" marginBottom="1.5rem" marginLeft="0.6rem">
          <Button
            variant="contained"
            className="pull-right ml-4 mr-3"
            onClick={handleSubmit}
            sx={{
              color: "#fff",
            }}
          >
            Add
          </Button>
        </Box>
      </Grid>

      {roleList && (
        <Grid xs={12} item>
          <Table style={{ width: "100%" }} aria-label="customized table">
            <CMTableHeader headers={roleTable} />
            <TableBody>
              {roleList &&
                roleList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">{item.title}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </MDBox>
  );
}

export default CreateRole;
