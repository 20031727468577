export const userTable = {
  head: [
    {
      title: "Sr no.",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Name",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Email",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Type",
      css: { width: "20%", border: "1px soild #ccc" },
    },
    {
      title: "Status",
      css: { width: "20%", border: "1px soild #ccc" },
    },
  ],
};

export const userTypeOptions = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "company",
    label: "Company",
  },
];
