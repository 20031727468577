import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { setAlert } from "../../actions/common";
import { onboardIssuerWatcher } from "../../actions/issuer.js";

const createIssuer = (props) => {
  const dispatch = useDispatch();

  const [issuerFirstName, setIssuerFirstName] = useState("");
  const [issuerUserName, setIssuerUserName] = useState("");
  const [issuerMiddleName, setIssuerMiddleName] = useState("");
  const [issuerLastName, setIssuerLastName] = useState("");
  const [issuerEmailId, setIssuerEmailId] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleClear = () => {
    setIssuerFirstName("");
    setIssuerMiddleName("");
    setIssuerLastName("");
    setIssuerEmailId("");
    setCompanyName("");
    setIssuerUserName("");
  };

  const handleSubmit = () => {
    if (
      issuerFirstName === "" ||
      issuerLastName === "" ||
      issuerEmailId === "" ||
      companyName === ""
    ) {
      dispatch(setAlert(false, "All fields are required", "error"));
      return;
    }
    const reqData = {
      firstname: issuerFirstName,
      middlename: issuerMiddleName,
      lastname: issuerLastName,
      username: issuerUserName,
      email: issuerEmailId,
      company_id: companyName,
    };
    new Promise((resolve, reject) => {
      dispatch(onboardIssuerWatcher(reqData, resolve, reject));
    })
      .then((response) => {
        dispatch(setAlert(false, response.message, "success"));
        handleClear();
      })
      .catch((error) => {
        dispatch(setAlert(false, error.response.data.message, "error"));
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant="h3" component="h2">
        Onboard Issuer
      </Typography>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Issuer
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the issuer.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Issuer Information</MDTypography>
                <MDBox mt={3} minWidth="10%">
                  <Grid container direction="row">
                    <Grid
                      xs={5}
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="standard-basic"
                          label="Issuer Firstname"
                          variant="standard"
                          type="text"
                          placeholder="Issuer Firstname"
                          value={issuerFirstName}
                          size="medium"
                          onChange={(event) => {
                            setIssuerFirstName(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="company"
                          label="Issuer Lastname"
                          variant="standard"
                          type="text"
                          placeholder="Issuer Lastname"
                          value={issuerLastName}
                          size="medium"
                          onChange={(event) => {
                            setIssuerLastName(event.target.value);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="standard-basic"
                          label="Issuer email"
                          variant="standard"
                          type="text"
                          placeholder="Issuer email"
                          value={issuerEmailId}
                          size="medium"
                          onChange={(event) => {
                            setIssuerEmailId(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={5}
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        ml: 1,
                      }}
                    >
                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="standard-basic"
                          label="Issuer Middlename"
                          variant="standard"
                          type="text"
                          placeholder="Issuer Middlename"
                          value={issuerMiddleName}
                          size="medium"
                          onChange={(event) => {
                            setIssuerMiddleName(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="standard-basic"
                          label="Issuer Username"
                          variant="standard"
                          type="text"
                          placeholder="Issuer Username"
                          value={issuerUserName}
                          size="medium"
                          onChange={(event) => {
                            setIssuerUserName(event.target.value);
                          }}
                        />
                      </FormControl>

                      <FormControl
                        variant="standard"
                        component={Box}
                        marginBottom="1.5rem!important"
                      >
                        <TextField
                          id="company"
                          label="Company name"
                          variant="standard"
                          type="text"
                          placeholder="Company"
                          value={companyName}
                          size="medium"
                          onChange={(event) => {
                            setCompanyName(event.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSubmit}
                    style={{ width: 10 }}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default createIssuer;
