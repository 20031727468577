export const userTable = {
  head: [
    {
      title: "Name",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Occupation",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Gender",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Education level",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Yearly income",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Investment goal",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Source income",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Years of working",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "User's bank",
      css: { width: "10%", border: "1px soild #ccc" },
    },
    {
      title: "Risk profile",
      css: { width: "10%", border: "1px soild #ccc" },
    },
  ],
};

export const userTypeOptions = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "company",
    label: "Company",
  },
];
