import { takeLatest, call, put } from "redux-saga/effects";
import { addProduct } from "../apis/product";

export function* addProductEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const response = yield call(addProduct, action.payload);
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* addProductWatcherSaga() {
  yield takeLatest("ADD_PRODUCT", addProductEffectSaga);
}
