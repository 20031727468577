import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { storedList } from "../../util/localstorage";
import CMTableHeader from "../../components/CMTable/CMTableHeader";
import { setAlert } from "../../actions/common";

import {  getUserProfileWatcher } from "../../actions/user";
import { userTable } from "./UserTableProps";


export default function CreateUser() {
  const [name, setName] = useState("");
  const [searching, setSearching] = useState(false);
  const [user, setUser] = useState("");
  const [occupation, setOccupation] = useState("");
  const [gender, setGender] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchOccupation, setSearchOccupation] = useState("");
  const [searchGender, setSearchGender] = useState("");
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(storedList("user"));
    window.addEventListener("storage", () => {
      setUser(storedList("user"));
    });
  }, []);

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const searchUserProfile = () => {
    setPage(0);    
    setSearchName(name);
    setSearchOccupation(occupation);
    setSearchGender(gender);
  };

  const fetchUserList = (payload) => {
    new Promise((resolve, reject) => {
      dispatch(getUserProfileWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setUserList(response.data);
        setCount(response.totalCount);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    const payload = {
      name : searchName,
      occupation: searchOccupation,
      gender: searchGender,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  }, [page, rowsPerPage]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setWalletLedgerList("");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    // setWalletLedgerList("");
  };

  useEffect(() => {
    const payload = {
      name : searchName,
      occupation: searchOccupation,
      gender: searchGender,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  }, [searchName, searchOccupation,searchGender]);

  const handleClear = () => {
    setSearchName("");
    setSearchOccupation("");
    setSearchGender("");
    const payload = {
      name : searchName,
      occupation: searchOccupation,
      gender: searchGender,
      page,
      limit: rowsPerPage,
    };
    fetchUserList(payload);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        {user.type === "admin" && (
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Name"
                  value={name}
                  type="text"
                  onChange={handleInputChange(setName)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Occupation"
                  value={occupation}
                  type="text"
                  onChange={handleInputChange( setOccupation)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <TextField
                  variant="standard"
                  label="Gender"
                  value={gender}
                  type="text"
                  onChange={handleInputChange( setGender)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} marginY={1} item>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={handleClear}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={searchUserProfile}
                  sx={{
                    color: "#fff",
                  }}
                >
                  {searching ? "Searching" : "Search"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
        {/* {userList && ( */}
          {userList ? (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          <Grid xs={12} item>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <CMTableHeader headers={userTable} />
              <TableBody>
                {userList &&
                  userList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">{item.name}</TableCell>
                      <TableCell align="left">{item.occupation}</TableCell>
                      <TableCell align="left">{item.gender}</TableCell>
                      <TableCell align="left">{item.educational}</TableCell>
                      <TableCell align="left">{item.income_per_year}</TableCell>
                      <TableCell scope="row">{item.investment_goal}</TableCell>
                      <TableCell align="left">{item.source_income}</TableCell>
                      <TableCell align="left">{item.years_of_working}</TableCell>
                      <TableCell align="left">{item.bank}</TableCell>
                      <TableCell align="left">{item.risk_profile}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        {/* )} */}
      </MDBox>
    </DashboardLayout>
  );
}
