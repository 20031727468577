import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { setAlert } from "../../actions/common";
import { addDepartmentWatcher, getDepartmentWatcher } from "../../actions/roleMetrix";
import CMTableHeader from "../../components/CMTable/CMTableHeader";
import { departmentTable } from "./roleMetrixTable";

function CreateDepartment() {
  const dispatch = useDispatch();
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);

  const getDepartmentList = () => {
    new Promise((resolve, reject) => {
      dispatch(getDepartmentWatcher(resolve, reject));
    })
      .then((response) => {
        setDepartmentList(response);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    getDepartmentList();
  }, []);

  const handleSubmit = () => {
    const data = {
      title: department,
    };
    new Promise((resolve, reject) => {
      dispatch(addDepartmentWatcher(data, resolve, reject));
    })
      .then((response) => {
        setDepartment("");
        dispatch(setAlert(false, response.message, "success"));
        getDepartmentList();
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  return (
    <MDBox py={3} mb={20} height="65vh">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl
            variant="filled"
            component={Box}
            width="100%"
            marginBottom="1.5rem!important"
          >
            <TextField
              id="outlined-basic"
              label="Department"
              variant="outlined"
              type="text"
              placeholder="Department"
              value={department}
              onChange={(event) => {
                setDepartment(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Box textAlign="center" marginTop="0.5rem" marginBottom="1.5rem" marginLeft="0.6rem">
          <Button
            variant="contained"
            className="pull-right ml-4 mr-3"
            onClick={handleSubmit}
            sx={{
              color: "#fff",
            }}
          >
            Add
          </Button>
        </Box>
      </Grid>

      {departmentList && (
        <Grid xs={12} item>
          <Table style={{ width: "100%" }} aria-label="customized table">
            <CMTableHeader headers={departmentTable} />
            <TableBody>
              {departmentList &&
                departmentList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">{item.title}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </MDBox>
  );
}

export default CreateDepartment;
