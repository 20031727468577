import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';
import * as xlsx from "xlsx";
import { storedList } from "../../util/localstorage";
import CMTableHeader from "../../components/CMTable/CMTableHeader";
import { setAlert } from "../../actions/common";
import MDDatePicker from "../../components/MDDatePicker/index.js";
import {  getReportWatcher,downloadReportWatcher } from "../../actions/analysis";
import { userTable } from "./UserTableProps";


export default function CreateUser() {
  const [userList, setUserList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [user, setUser] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formatedFromDate,setFormatedFromDate] = useState("");
  const [formatedToDate,setFormatedToDate] = useState("");
  const [searchFormatedFromDate,setSearchFormatedFromDate] = useState("");
  const [searchFormatedToDate,setSearchFormatedToDate] = useState("");


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(storedList("user"));
    window.addEventListener("storage", () => {
      setUser(storedList("user"));
    });
  }, []);

  const searchReportList = () => {
    setPage(0);    
    setSearchFormatedFromDate(formatedFromDate);
    setSearchFormatedToDate(formatedToDate);
  };

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const fetchReportList = (payload) => {
    new Promise((resolve, reject) => {
      dispatch(getReportWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setUserList(response.rows);
        setCount(response.count);
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  };

  useEffect(() => {
    const payload = {
      from_date:searchFormatedFromDate,
      to_date:searchFormatedToDate,
      page,
      limit: rowsPerPage,
    };
    fetchReportList(payload);
  }, [page, rowsPerPage]);

  const handleClear = () => {
    setSearchFormatedFromDate("");
    setSearchFormatedToDate("");
    setFormatedFromDate("");
    setFormatedToDate("");
    const payload = {
      from_date:searchFormatedFromDate,
      to_date:searchFormatedToDate,
      page,
      limit: rowsPerPage,
    };
    fetchReportList(payload);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  useEffect(() => {
    const payload = {
      from_date:searchFormatedFromDate,
      to_date:searchFormatedToDate,
      page,
      limit: rowsPerPage,
    };
    fetchReportList(payload);
  }, [searchFormatedFromDate, searchFormatedToDate]);

  const convertJsonToExcel = (data,reportName,reportDate) => {
    const workSheet = xlsx.utils.json_to_sheet(data);
    const workBook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workBook, workSheet, "report")
    // Generate buffer
    xlsx.write(workBook, { bookType: 'xlsx', type: "buffer" })

    // Binary string
    xlsx.write(workBook, { bookType: "xlsx", type: "binary" })

    xlsx.writeFile(workBook, `${reportName + reportDate}.xlsx`)
  }

  const downloadReport = (id,reportName,reportDate) => {
    const payload = {
      id
    };
    new Promise((resolve, reject) => {
      dispatch(downloadReportWatcher(payload, resolve, reject));
    })
      .then((response) => {
        convertJsonToExcel(response,reportName,reportDate)
      })
      .catch((error) => {
        dispatch(
          setAlert(false, error.response?.data?.message || error.response?.data?.msg, "error")
        );
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        {user.type === "admin" && (
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
              <label>Report from Date: </label>
              <MDDatePicker
              input={{ placeholder: "From report date" }} 
                value={formatedFromDate}
                options={{
                  enableTime: false,
                  dateFormat: "Y-m-d",
              }}
                onChange={(date,dateStr) => {
                  setFormatedFromDate(dateStr);
                  // setReportDate(date);
                }}
              />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
              <label>Report to Date: </label>
              <MDDatePicker
              input={{ placeholder: "To report date" }} 
                value={formatedToDate}
                options={{
                  enableTime: false,
                  dateFormat: "Y-m-d",
              }}
                onChange={(date,dateStr) => {
                  setFormatedToDate(dateStr);
                }}
              />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} marginY={1} item>
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={handleClear}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  className="pull-right ml-4 mr-3"
                  onClick={searchReportList}
                  sx={{
                    color: "#fff",
                  }}
                >
                  {searching ? "Searching" : "Search"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
        {/* {userList && ( */}
          {userList ? (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          <Grid xs={12} item>
            <Table style={{ width: "100%" }} aria-label="customized table">
              <CMTableHeader headers={userTable} />
              <TableBody>
                {userList &&
                  userList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">{item.report_name}</TableCell>
                      <TableCell align="left">{Moment(item.report_date).format('DD-MM-YYYY')}</TableCell>
                      <TableCell align="left">
                        <Button variant="contained"
                        color="primary"
                        onClick={() => downloadReport(item._id,item.report_name,Moment(item.report_date).format('DD-MM-YYYY'))}
                        >Download</Button>{item.Amount}
                        </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        {/* )} */}
      </MDBox>
    </DashboardLayout>
  );
}
