/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import TextField from "@mui/material/TextField";
import { setAlert } from "../../../../actions/common";
import { resetpasswordWatcher,validateOtpWatcher } from "../../../../actions/user";



function Cover() {

  const dispatch = useDispatch();

  const [email,setEmail] = useState("");
  const [showEmail,setShowEmail] = useState(true);
  const [showValidateOtp,setShowValidateOtp] = useState(false);
  const [OTP,setOTP] = useState("");
  const [resetting,setResetting] = useState(false);
  const [validating,setvalidating] = useState(false);


  const navigate = useNavigate();

  const handleReset = () => {
    setResetting(true);
    const postData = {email}
    const p = new Promise((resolve, reject) => {
    dispatch(resetpasswordWatcher(postData, resolve, reject));
  })
    .then((response) => {
      dispatch(setAlert(false, response.message, "success"));
      setShowValidateOtp(true);
      setShowEmail(false);
      setResetting(false);
    })
    .catch((error) => {
      dispatch(setAlert(false, error.response.data.message, "error"));
      setResetting(false);
    });
}

const handleValidateOTP = () => {
  setvalidating(true);
  const postData = {OTP,email}
  const p = new Promise((resolve, reject) => {
    dispatch(validateOtpWatcher(postData, resolve, reject));
  })
    .then((response) => {
      dispatch(setAlert(false, response.message, "success"));
      navigate("/authentication/log-in");
      setvalidating(false);
    })
    .catch((error) => {
      dispatch(setAlert(false, error.response.data.message, "error"));
      setvalidating(false);
    });
}

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {showEmail ? (
          <MDBox component="form" role="form">
            <MDBox mb={4}>
            <TextField
                type="text"
                label="Email"
                placeholder="Email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleReset}>
              {resetting ? "Resetting..." : "Reset"}
              </MDButton>
            </MDBox>
          </MDBox>
          ):null}
          { showValidateOtp ? (
          <MDBox component="form" role="form">
            <MDBox mb={4}>
            <TextField
                type="text"
                label="OTP"
                placeholder="OTP"
                value={OTP}
                onChange={(event) => {
                  setOTP(event.target.value);
                }}
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleValidateOTP}>
              {validating ? "validating..." : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        ) : null}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
